import { zodResolver } from '@hookform/resolvers/zod';
import { requiredFieldMessage } from 'piramid-constants';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  useToast,
} from 'ui';
import { z } from 'zod';
import { useApiError } from '../hooks/useApiError';

const schema = z.object({
  email: z.string().email().min(1, requiredFieldMessage),
});

type FormData = z.infer<typeof schema>;

export const ForgotPasswordForm = ({
  requestLinkForgotPassword,
}: {
  requestLinkForgotPassword: (data: FormData) => Promise<void>;
}) => {
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [requested, setRequested] = useState(false);

  const { handleError } = useApiError();

  const { toast } = useToast();

  const handleSubmit = useCallback((data: FormData) => {
    setIsLoading(true);

    requestLinkForgotPassword(data)
      .then(() => {
        setRequested(true);
        toast({
          title: `Se envio un email de recuperacion de contrasena al email ${data.email}`,
        });
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Form {...form}>
      <form className='w-full' onSubmit={form.handleSubmit(handleSubmit)}>
        <FormField
          control={form.control}
          name='email'
          defaultValue=''
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Correo electrónico</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type='email'
                    className='h-11.1 rounded-[.625rem] border-solid border-primary placeholder:text-stone-300 placeholder:text-xs focus:border- focus-visible:ring-transparent'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <Button
          loading={isLoading}
          type='submit'
          className='mt-6 bg-primary hover:bg-primary'
        >
          Solicitar enlace de recuperación
        </Button>
      </form>
    </Form>
  );
};
