import { IconCircleCheckFilled } from '@tabler/icons-react';
import React, { useContext, useMemo } from 'react';
import {
  Card,
  CardContent,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  cn,
} from 'ui';

interface TimelineContextProps {
  itemsCount: number;
}

const TimelineContext = React.createContext<TimelineContextProps>({
  itemsCount: 0,
});

const useTimeline = () => useContext(TimelineContext);

export const TimelineProvider = ({
  children,
  itemsCount,
}: {
  children: React.ReactNode;
  itemsCount: number;
}) => {
  return (
    <TimelineContext.Provider value={{ itemsCount }}>
      {children}
    </TimelineContext.Provider>
  );
};

export const TimelineCarouselItemLabel = ({
  children,
  size,
}: {
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
}) => {
  return (
    <div className='flex flex-row items-center space-x-3'>
      <span
        className={cn('font-semibold text-sm', {
          'text-sm': size === 'sm',
          'text-md': size === 'md',
          'text-lg': size === 'lg',
        })}
      >
        {children}
      </span>
    </div>
  );
};

export const TimelineCarouselItemContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='flex flex-col'>{children}</div>;
};

export const TimelineCarouselItemHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='flex flex-row items-center space-x-2'>{children}</div>;
};

export const TimelineCarouselItemCaption = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='font-semibold text-sm'>{children}</div>;
};

export const TimelineCarouselItemMarker = ({ index }: { index: number }) => {
  const { itemsCount } = useTimeline();

  const isLatestTransition = index === itemsCount - 1;

  return (
    <div>
      {!isLatestTransition ? (
        <IconCircleCheckFilled className='w-6 h-6' />
      ) : (
        <div className='rounded-full bg-primary text-white py-1 text-xs font-semibold w-[24px] h-[24px] text-center'>
          <span>{index + 1}</span>
        </div>
      )}
    </div>
  );
};

export const TimelineCarouselItem = ({
  index,
  children,
}: {
  index: number;
  children: React.ReactNode;
}) => {
  const { itemsCount } = useTimeline();

  const flexBasis = useMemo(() => {
    const itemCount = itemsCount;
    if (itemCount <= 3) {
      return `${100 / itemCount}%`;
    }
    return '33%';
  }, [itemsCount]);

  const isLatestTransition = index === itemsCount - 1;

  return (
    <CarouselItem
      style={{
        flexBasis,
      }}
      className='pl-6 w-96 h-28'
    >
      <div
        className={cn('flex flex-row items-center space-x-4 h-full', {
          'opacity-30': !isLatestTransition,
        })}
      >
        {children}
      </div>
    </CarouselItem>
  );
};

export const TimelineCarousel = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { itemsCount } = useTimeline();

  return (
    <Carousel
      opts={{
        startIndex: itemsCount - 1,
      }}
      className='h-full flex items-center -ml-6'
      orientation='horizontal'
    >
      <div className='absolute left-2'>
        <CarouselPrevious
          classNames={{
            icon: 'text-stone-300 w-3 h-3',
          }}
          size={'xs'}
          className='p-0  w-4 h-4'
          withoutPosStyles
        />
      </div>

      <CarouselContent className='w-full'>{children}</CarouselContent>

      <div className='absolute -right-4'>
        <CarouselNext
          className='p-0 w-4 h-4'
          classNames={{
            icon: 'text-stone-400 w-3 h-3',
          }}
          withoutPosStyles
        />
      </div>

      {/* <div className='absolute -left-10'>
        <CarouselPrevious withoutPosStyles />
      </div> */}

      {/* <div className='absolute -right-16'>
        <CarouselNext withoutPosStyles />
      </div> */}
    </Carousel>
  );
};

export const Timeline = ({
  children,
  ...props
}: TimelineContextProps & {
  children: React.ReactNode;
}) => {
  return (
    <TimelineProvider {...props}>
      <Card className='shadow-none '>
        <CardContent className='pb-0 w-full relative'>{children}</CardContent>
      </Card>
    </TimelineProvider>
  );
};
