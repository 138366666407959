'use client';

import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { Button, ButtonProps } from './Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './Command';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '../lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip';
import { applyConditionsToVariables } from 'bff';
import { Tag } from 'database';

export const ComboboxButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    placeholder?: string;
    selected?: Pick<ComboboxOption, 'label'>;
    readonly?: boolean;
    withClear?: boolean;
  }
>(
  (
    {
      className,
      placeholder = 'Selecciona una opción',
      selected,
      size = 'input-sm',
      readonly = false,
      withClear = false,
      ...props
    },
    ref,
  ) => {
    const ButtonContent = (
      <Button
        disabled={!selected?.label}
        variant='outline'
        role='combobox'
        className={cn(
          'justify-between truncate max-w-xs w-full flex',
          className,
        )}
        data-test-id='combobox'
        size={size}
        {...props}
        ref={ref}
      >
        <span className='truncate'>
          {selected ? selected.label : placeholder}
        </span>
        {!readonly && (
          <div className=''>
            <IconChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          </div>
        )}
      </Button>
    );

    if (selected?.label) {
      return (
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>{ButtonContent}</TooltipTrigger>
            <TooltipContent>
              <p>{selected?.label}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return ButtonContent;
  },
);

export interface ComboboxOption<T = string | number> {
  label: string;
  value: T;
  options?: ComboboxOption[];
  group?: string;
}

export const ComboboxCheckbox = <T extends ComboboxOption>({
  render: RenderOption,
  value,
  loadOptions,
  onSelect,
  placeholder,
  emptyStateMessage = 'No se encontraron resultados',
  defaultOptions = [],
  icon,
  disabled = false,
  dependencyState = [],
  defaultValue,
  size = 'sm',
  className,
  onClose,
  withEmptySearchTerm = false,
  withPrefetch = false,
}: {
  icon?: React.ReactNode;
  loadOptions: (search: string) => Promise<T[]>;
  onSelect: (value: T) => void;
  placeholder: string;
  emptyStateMessage: string;
  render: React.FC<T>;
  value: ComboboxOption[];
  defaultOptions: ComboboxOption[];
  disabled?: boolean;
  dependencyState?: string[];
  defaultValue?: ComboboxOption | null | undefined;
  size?: 'xs' | 'sm' | 'lg';
  className?: string;
  onClose: () => void;
  withEmptySearchTerm?: boolean;
  withPrefetch?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [opts, setOpts] = useState<T[]>(
    !!defaultOptions && Array.isArray(defaultOptions) ? defaultOptions : [],
  );

  const wrappedFunc = useCallback(
    (search: string = '') => {
      if (!search && !withEmptySearchTerm) {
        setOpts([]);
        setIsLoading(false);
        return;
      }

      loadOptions(search)
        .then((res) => {
          setOpts(res);
        })
        .finally(() => setIsLoading(false));
    },
    [...dependencyState],
  );

  const debounced = useCallback(debounce(wrappedFunc, 500), [
    ...dependencyState,
  ]);

  useEffect(() => {
    loadOptions('').then(setOpts);
  }, []);

  return (
    <Command shouldFilter={false}>
      <CommandInput
        disabled={disabled}
        loading={isLoading}
        placeholder={placeholder}
        onValueChange={(search) => {
          setIsLoading(true);
          debounced(search);
        }}
      />

      <CommandList>
        <CommandEmpty>
          {isLoading ? 'Buscando...' : emptyStateMessage}
        </CommandEmpty>
        <CommandGroup>
          {(opts || []).map((opt, index) => {
            const selected = value.some(
              (ele) => ele.value.toString() === opt.value.toString(),
            );

            return (
              <CommandItem
                key={index}
                value={opt.value.toString()}
                onSelect={() => onSelect(opt)}
              >
                <div
                  className={cn(
                    'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                    {
                      'bg-primary text-primary-foreground': selected,
                      'opacity-50 [&_svg]:invisible': !selected,
                    },
                  )}
                >
                  <IconCheck className='w-4 h-4' />
                </div>
                <RenderOption {...opt} />
              </CommandItem>
            );
          })}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export interface ComboboxProps<T extends ComboboxOption> {
  withGroupOptionsMulti?: boolean;
  withEmptySearchTerm?: boolean;
  withPrefetch?: boolean;
  icon?: React.ReactNode;
  loadOptions: (search: string) => Promise<T[]>;
  onSelect: (value: T, notifyCommit?: () => void) => void;
  placeholder: string;
  emptyStateMessage: string;
  render: React.FC<T>;
  value?: string;
  defaultOptions: ComboboxOption[];
  disabled?: boolean;
  dependencyState?: string[];
  defaultValue?: ComboboxOption[] | null | undefined | ComboboxOption;
  size?: 'xs' | 'sm' | 'lg' | 'input' | 'input-sm';
  className?: string;
  mode?: 'async' | 'sync';
  popoverClassName?: string;
  withClear?: boolean;
  onCreate?: (value: string) => Promise<Tag>;
}

const OPTIONS_GROUP_SEPARATOR = '#';

export function Combobox<T extends ComboboxOption>({
  render: RenderOption,
  value,
  loadOptions,
  onSelect,
  placeholder,
  emptyStateMessage,
  defaultOptions = [],
  icon,
  disabled = false,
  dependencyState = [],
  defaultValue,
  size = 'sm',
  className,
  mode = 'sync',
  popoverClassName,
  withClear = false,
  withPrefetch = false,
  withEmptySearchTerm = false,
  withGroupOptionsMulti = true,
  classNames,
  withGlobalCommandEmptyState = true,
  style,
  styles,
  children,
  onCreate,
  onCreateLabel = 'Crear nuevo:',
  onOpenChange,
}: ComboboxProps<T> & {
  children?: React.ReactNode;
  withGlobalCommandEmptyState?: boolean;
  classNames?: {
    triggerContainer?: string;
    emptyState?: string;
    group?: string;
    placeholder?: string;
    searchInput?: string;
    item?: string;
  };
  style?: React.CSSProperties;
  styles?: {
    popover?: React.CSSProperties;
  };
  onCreateLabel?: string;
  onOpenChange?: (open: boolean) => void;
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const findOpt = useCallback(
    (opts: T[], target: string, key: keyof T) =>
      opts.find((opt) => opt[key] === target),
    [],
  );

  const [buttonWidth, setButtonWidth] = useState(0);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [opts, setOpts] = useState<T[]>(() => {
    if (
      defaultValue &&
      !Array.isArray(defaultValue) &&
      !defaultOptions.length &&
      !withPrefetch
    )
      return [defaultValue];

    return !!defaultOptions && Array.isArray(defaultOptions)
      ? defaultOptions
      : [];
  });

  const [selected, setSelected] = useState<T | undefined>(() => {
    if (defaultValue) return defaultValue;

    if (value) return opts;

    return undefined;
  });
  useEffect(() => {
    setSelected((prevState) => {
      if (value === undefined || value === '') return undefined;
      const foundOpt = opts.find((opt) => opt.value === value);
      return foundOpt || prevState;
    });
  }, [value, opts]);

  const wrappedFunc = useCallback(
    (search: string = '') => {
      if (!search && !withEmptySearchTerm) {
        setOpts([]);
        setIsLoading(false);
        return;
      }

      loadOptions(search)
        .then((res) => {
          setOpts(res);
        })
        .finally(() => setIsLoading(false));
    },
    [...dependencyState],
  );

  const debounced = useCallback(debounce(wrappedFunc, 500), [
    ...dependencyState,
  ]);

  useEffect(() => {
    if (withPrefetch) {
      debounced('');
    }
  }, []);

  const onSelectSingleOption = ({
    opt,
    currentValue,
  }: {
    opt: ComboboxOption;
    currentValue: string;
  }) => {
    const isClearing = value === opt.value && withClear;

    const localCommit = () => {
      const getSelectedOption = () => {
        if (currentValue.includes(OPTIONS_GROUP_SEPARATOR)) {
          const [value, group] = currentValue.split(OPTIONS_GROUP_SEPARATOR);

          console.log('group', group, 'value', value, 'opts', opts);

          return findOpt(
            opts.find((opt) => opt.group === group)!.options,
            value,
            'value',
          );
        }

        return findOpt(opts, currentValue, 'value');
      };

      const selectedOpt = getSelectedOption();

      console.log(
        'selectedOpt',
        selectedOpt,
        'currentValue',
        currentValue,
        'opts',
        opts,
      );

      if (isClearing) {
        setSelected(null);
      }

      if (!isClearing) {
        setSelected(selectedOpt);
      }

      setOpen(false);
    };

    if (mode === 'sync') {
      onSelect(isClearing ? null : opt);
      localCommit();
    }

    if (mode === 'async') {
      onSelect(isClearing ? null : opt, () => {
        localCommit();
        wrappedFunc(searchTerm);
      });
    }
  };

  console.log('buttonWidth', buttonWidth);

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        onOpenChange?.(open);

        if (disabled) return;

        setOpen(open);
      }}
    >
      <PopoverTrigger asChild>
        <span
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
          }}
          ref={(ele) => {
            if (ele) {
              console.log('next buttonWidth', ele.offsetWidth);

              setButtonWidth(ele.offsetWidth);
            }
          }}
          className={cn('w-full flex flex-row items-center')}
        >
          <Button
            onClick={() => {
              const nextOpen = !open;
              setOpen(nextOpen);
              onOpenChange?.(nextOpen);
            }}
            style={style}
            size={size}
            variant='outline'
            role='combobox'
            aria-expanded={open}
            className={cn(
              'justify-between truncate max-w-xs w-full flex relative',
              className,
            )}
            data-test-id='combobox'
          >
            <span className={cn('truncate', classNames?.placeholder)}>
              {selected ? selected.label : placeholder}
            </span>

            {!disabled &&
              (icon || (
                <div className=''>
                  <IconChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
                </div>
              ))}
          </Button>
          {children}
        </span>
      </PopoverTrigger>

      <PopoverContent
        align='end'
        className={cn('w-full p-0 popover-content', popoverClassName)}
        style={{
          width: buttonWidth,
          opacity: open ? 1 : 0,
          transition: 'opacity 150ms ease-in-out',
        }}
        onCloseAutoFocus={(e) => {
          e.preventDefault();
          setSearchTerm('');
          setIsLoading(false);
        }}
      >
        <Command className='w-full' shouldFilter={false}>
          <CommandInput
            className={cn('w-full', classNames?.searchInput)}
            disabled={disabled}
            loading={isLoading}
            placeholder={placeholder}
            value={searchTerm}
            onValueChange={(search) => {
              setSearchTerm(search);
              setIsLoading(true);
              debounced(search);
            }}
          />

          <CommandList>
            {withGlobalCommandEmptyState && (
              <CommandEmpty
                className={cn(
                  classNames?.emptyState,
                  'transition-opacity duration-150 p-[.875rem]',
                )}
                style={{
                  opacity: isLoading ? 0 : 1,
                }}
              >
                {isLoading ? (
                  'Buscando...'
                ) : onCreate && searchTerm ? (
                  <div
                    className='flex items-center justify-start cursor-pointer'
                    onClick={async () => {
                      const newOption = await onCreate(searchTerm);

                      const selectedOption = {
                        label: newOption.name,
                        value: newOption.id,
                      } as T;

                      setSearchTerm('');
                      setOpen(false);
                      setSelected(selectedOption);
                      onSelect(selectedOption);
                    }}
                  >
                    <span className='me-2 font-medium text-foreground-secondary text-xs'>
                      {onCreateLabel}
                    </span>
                    <span className='font-medium text-foreground py-1 px-[.375rem] border border-dashed border-primary rounded-[.5rem] text-xs'>
                      {searchTerm}
                    </span>
                  </div>
                ) : (
                  emptyStateMessage
                )}
              </CommandEmpty>
            )}

            {(opts || []).map((opt, index) => {
              if (opt.options) {
                return (
                  <CommandGroup
                    className={classNames?.group}
                    key={index}
                    heading={opt.label}
                  >
                    {opt.options.length > 0 ? (
                      opt.options.map((groupOpt, index) => {
                        const value = `${groupOpt.value}${OPTIONS_GROUP_SEPARATOR}${opt.value}`;

                        return (
                          <CommandItem
                            className={cn(classNames?.item)}
                            key={index}
                            value={value}
                            onSelect={(currentValue) => {
                              if (withGroupOptionsMulti) {
                                onSelect(
                                  currentValue === value ? '' : currentValue,
                                );

                                setSelected((prev) =>
                                  prev.concat([
                                    findOpt(opts, currentValue, 'value'),
                                  ]),
                                );

                                setOpen(false);
                              }

                              if (!withGroupOptionsMulti) {
                                onSelectSingleOption({
                                  currentValue,
                                  opt: groupOpt,
                                });
                              }
                            }}
                          >
                            <RenderOption {...groupOpt} group={opt.value} />
                          </CommandItem>
                        );
                      })
                    ) : (
                      <p className={cn('text-sm pl-2', classNames?.emptyState)}>
                        No se encontraron resultados
                      </p>
                    )}
                  </CommandGroup>
                );
              }

              return (
                <CommandItem
                  className={cn(classNames?.item)}
                  key={index}
                  value={opt.value}
                  onSelect={(currentValue) =>
                    onSelectSingleOption({
                      currentValue,
                      opt,
                    })
                  }
                >
                  <RenderOption {...opt} />
                </CommandItem>
              );
            })}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
