'use client';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SeparatorText,
  ToastAction,
} from 'ui';
import { zodResolver } from '@hookform/resolvers/zod';
import Link from 'next/link';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { requiredFieldMessage } from 'piramid-constants';
import { ApiErrorCallbacks, useApiError } from '../hooks/useApiError';
import { cn } from 'ui';
import { SignInWithGoogle } from './SignInWithGoogle';
import { SignInRequestBody } from 'bff';
import { Loader2 } from 'lucide-react';

const schema = z.object({
  email: z.string().email().min(1, requiredFieldMessage),
  password: z.string().min(1, requiredFieldMessage),
});

type FormData = z.infer<typeof schema>;

export type AuthenticateFormData = FormData;

export type SignInIDPFn = (data: SignInRequestBody) => Promise<any>;

export interface AuthenticateFormProps {
  forgotPasswordHref?: string;
  callbacks?: ApiErrorCallbacks<true>;
  context?: 'login' | 'register';
  back: {
    handle: () => void;
    label: string;
  };
  submit: {
    label: string;
    handle: (data: FormData) => Promise<any>;
  };
}

export const BackToButton = ({
  handleBack,
  children,
}: {
  handleBack: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <Button
      type='button'
      variant={'link'}
      className='w-full text-sm text-stone-300 hover:no-underline pointer-events-none'
    >
      <span
        className='ml-2 font-semibold text-[.9375rem] pointer-events-auto cursor-pointer text-stone-300'
        onClick={(e) => {
          e.stopPropagation();
          handleBack();
        }}
      >
        {children}
      </span>
    </Button>
  );
};

export const AuthenticateForm = ({
  submit,
  context,
  forgotPasswordHref,
  callbacks,
  back,
}: AuthenticateFormProps) => {
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [emailTyped, setEmailTyped] = useState(false);

  const { handleError } = useApiError({
    user_not_verified: async (data) => {
      if (callbacks?.user_not_verified) {
        await callbacks?.user_not_verified({
          errorMetadata: data.errorMetadata,
          credentials: form.getValues(),
        });
      }

      setIsLoading(false);
    },
  });

  const handleSubmit = useCallback(
    (data: FormData) => {
      setIsLoading(true);

      return submit.handle(data).catch(async (err) => {
        const handlingResult = await handleError(err);

        console.log('handlingResult', handlingResult);

        if (!handlingResult.callbackInvoked) {
          setIsLoading(false);
        }
      });
    },
    [submit.handle],
  );

  return (
    <div className='animate-fadein'>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSubmit)}
          className='grid grid-cols-1 mb-5 '
        >
          {!emailTyped && (
            <div className='w-full grid grid-cols-1 gap-4'>
              <FormField
                control={form.control}
                name='email'
                defaultValue=''
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormControl>
                        <Input
                          {...field}
                          className='h-11.1 rounded-[.625rem] border-solid border-primary placeholder:text-stone-300 placeholder:text-xs focus:border- focus-visible:ring-transparent !bg-piramid-white'
                          placeholder='Ingresa tu email'
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              form.trigger('email').then((isValid) => {
                                if (isValid) {
                                  setEmailTyped(true);
                                }
                              });
                            }
                          }}
                          autoFocus
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <Button
                loading={isLoading}
                type='button'
                className='bg-primary hover:bg-primary h-11.1 rounded-[.625rem] text-[1rem]'
                onClick={async () => {
                  if (await form.trigger('email')) {
                    setEmailTyped(true);
                  }
                }}
              >
                Continuar
              </Button>
            </div>
          )}

          {emailTyped && (
            <div className='w-full grid grid-cols-1 animate-fadein'>
              <FormField
                control={form.control}
                name='password'
                defaultValue=''
                render={({ field }) => {
                  return (
                    <FormItem
                      className={cn({
                        'mb-4': context !== 'login',
                      })}
                    >
                      <FormControl>
                        <Input
                          type='password'
                          {...field}
                          className='h-11.1 rounded-[.625rem] border-solid border-primary placeholder:text-stone-300 placeholder:text-xs focus:border- focus-visible:ring-transparent !bg-piramid-white'
                          placeholder='Ingresa tu contraseña'
                          autoFocus
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              {context === 'login' && (
                <div className='text-right mb-4'>
                  <Link
                    className='text-xs hover:underline text-semibold text-foreground inline-block'
                    href={forgotPasswordHref || '/forgot-password'}
                  >
                    Olvidé mi contraseña
                  </Link>
                </div>
              )}

              <Button
                loading={isLoading}
                type='submit'
                className='bg-primary hover:bg-primary h-11.1 rounded-[.625rem] text-[1rem]'
              >
                {submit.label}
              </Button>
            </div>
          )}

          {back && (
            <Button
              type='button'
              variant={'link'}
              className='mt-[27px] w-full text-sm text-[#B7B7C0] hover:no-underline pointer-events-none'
            >
              <span
                className='ml-2 font-semibold text-[.9375rem] pointer-events-auto cursor-pointer text-stone-300'
                onClick={(e) => {
                  e.stopPropagation();
                  back.handle();
                }}
              >
                {back.label}
              </span>
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
};
