import { IconCheck, IconSelector } from '@tabler/icons-react';

import * as React from 'react';

import * as RPNInput from 'react-phone-number-input';

import flags from 'react-phone-number-input/flags';

import { Button, buttonVariants } from './Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './Command';
import { Input, InputProps, inputVariants } from './Input';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';

import { ScrollArea } from './ScrollArea';
import { cn } from '../lib/utils';

type PhoneInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value'
> &
  Omit<RPNInput.Props<typeof RPNInput.default>, 'onChange'> & {
    onChange?: (value: RPNInput.Value) => void;
    withBlackBorder?: boolean;
  };

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> =
  React.forwardRef<React.ElementRef<typeof RPNInput.default>, PhoneInputProps>(
    ({ className, onChange, withBlackBorder, ...props }, ref) => {
      const WrappedInput = React.useMemo(
        () =>
          React.forwardRef((props, ref) => (
            <InputComponent
              {...props}
              ref={ref}
              withBlackBorder={withBlackBorder}
            />
          )),
        [],
      );

      return (
        <RPNInput.default
          ref={ref}
          className={cn('flex', className)}
          flagComponent={FlagComponent}
          countrySelectComponent={React.forwardRef((props, ref) => (
            <CountrySelect
              {...props}
              ref={ref}
              withBlackBorder={withBlackBorder}
            />
          ))}
          inputComponent={WrappedInput}
          /**
           * Handles the onChange event.
           *
           * react-phone-number-input might trigger the onChange event as undefined
           * when a valid phone number is not entered. To prevent this,
           * the value is coerced to an empty string.
           *
           * @param {E164Number | undefined} value - The entered value
           */
          onChange={(value) => onChange?.(value || '')}
          {...props}
        />
      );
    },
  );
PhoneInput.displayName = 'PhoneInput';

const InputComponent = React.forwardRef<
  HTMLInputElement,
  InputProps & { withBlackBorder?: boolean }
>(({ className, withBlackBorder, ...props }, ref) => (
  <Input
    className={cn(
      'w-full rounded-e-md rounded-s-none placeholder:text-[.875rem] placeholder:text-[#B7B7C0] placeholder:font-normal ',
      className,
      {
        'border-primary': withBlackBorder,
      },
    )}
    {...props}
    ref={ref}
    variant={'outlineNoRings'}
  />
));
InputComponent.displayName = 'InputComponent';

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country) => void;
  options: CountrySelectOption[];
  withBlackBorder?: boolean;
};

const CountrySelect = ({
  disabled,
  value,
  onChange,
  options,
  withBlackBorder,
}: CountrySelectProps) => {
  const handleSelect = React.useCallback(
    (country: RPNInput.Country) => {
      onChange(country);
    },
    [onChange],
  );

  console.log('withBlackBorder', withBlackBorder);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type='button'
          variant={'outline'}
          className={cn('flex gap-1 rounded-e-none rounded-s-md px-3', {
            'border-t-primary  border-l-primary border-b-primary':
              withBlackBorder,
          })}
          disabled={disabled}
        >
          <FlagComponent country={value} countryName={value} />
          <IconSelector
            className={cn(
              '-mr-2 h-4 w-4 opacity-50',
              disabled ? 'hidden' : 'opacity-100',
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[300px] p-0'>
        <Command>
          <CommandList>
            <ScrollArea orientation='vertical' className='h-72'>
              <CommandInput placeholder='Buscar país...' />
              <CommandEmpty>No se encontraron resultados</CommandEmpty>
              <CommandGroup>
                {options
                  .filter((x) => x.value)
                  .map((option) => (
                    <CommandItem
                      className='gap-2'
                      key={option.value}
                      onSelect={() => handleSelect(option.value)}
                    >
                      <FlagComponent
                        country={option.value}
                        countryName={option.label}
                      />
                      <span className='flex-1 text-sm'>{option.label}</span>
                      {option.value && (
                        <span className='text-foreground/50 text-sm'>
                          {`+${RPNInput.getCountryCallingCode(option.value)}`}
                        </span>
                      )}
                      <IconCheck
                        className={cn(
                          'ml-auto h-4 w-4',
                          option.value === value ? 'opacity-100' : 'opacity-0',
                        )}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
  const Flag = flags[country];

  return (
    <span className='bg-foreground/20 flex h-4 w-6 overflow-hidden rounded-sm'>
      {Flag && <Flag title={countryName} />}
    </span>
  );
};
FlagComponent.displayName = 'FlagComponent';

export { PhoneInput };
