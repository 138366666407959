import { User } from 'database';
import { AvatarProps, cn } from 'ui';
import { UserAvatar } from './UserAvatar';
import { capitalizeFirstLetter } from 'utils';

export type UserIdentity = Pick<
  User,
  'first_name' | 'last_name' | 'profile_photo' | 'email' | 'id'
>;

export const UserDisplay = <T extends UserIdentity>({
  user,
  withAvatar = true,
  children,
  withEmail = true,
  ...props
}: {
  user: T;
  withAvatar?: boolean;
  children?: React.ReactNode;
  withEmail?: boolean;
} & AvatarProps) => {
  return (
    <div className='flex flex-row items-center'>
      {withAvatar && <UserAvatar data={user} {...props} />}

      <div
        className={cn({
          'ml-2': withAvatar,
        })}
      >
        <p className='text-sm font-medium leading-none text-foreground-secondary'>
          {capitalizeFirstLetter(user.first_name.toLowerCase())}{' '}
          {capitalizeFirstLetter(user.last_name.toLowerCase())}
        </p>
        {withEmail && (
          <p className='text-xs text-muted-foreground'>{user.email}</p>
        )}
        {children}
      </div>
    </div>
  );
};
