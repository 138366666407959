'use client';
import {
  IconEdit,
  IconFileDescription,
  IconMapPin,
  IconPaperclip,
  IconUserShield,
} from '@tabler/icons-react';
import {
  FetchClaimDetailResponseBody,
  FetchInsurerClaimDetailResponseBody,
  UpdateClaimRequestBody,
} from 'bff';
import { format } from 'date-fns';
import { DEFAULT_COUNTRY_CODE } from 'piramid-constants';
import { FileList, FileListItem, FileListLabel } from 'shared-components';
import { Button, ScrollArea, cn, useToast } from 'ui';
import { currencyFormat, getCountryLabel, getStateLabel } from 'utils';
import {
  ClaimUpdateContext,
  ClaimUpdateProvider,
  ClaimUpdateSegment,
  useSafeClaimUpdate,
} from '../contexts/ClaimUpdateContext';
import { useCallback, useState } from 'react';
import { EditClaimButton } from './EditClaimButton';
import { ClaimSummary } from 'shared-components/components/ClaimSummary';

type Document = FetchClaimDetailResponseBody['documents'][number];

export const ClaimDetailCards = ({
  data,
  onOpenDocument,
  withEdit = false,
  onSave,
  onInvalidate,
  fetchers,
}: {
  data: FetchInsurerClaimDetailResponseBody;
  onOpenDocument?: (document: Document) => void;
  withEdit?: boolean;
  onSave?: (data: UpdateClaimRequestBody) => Promise<any>;
  onInvalidate?: () => void;
  fetchers?: ClaimUpdateContext['fetchers'];
}) => {
  return (
    <ClaimUpdateProvider
      enabled={withEdit}
      data={data}
      onInvalidate={onInvalidate}
      onSave={onSave}
      fetchers={fetchers}
    >
      <ClaimSummary.CardList>
        <ClaimSummary.CardGroup className='col-span-1'>
          <ClaimSummary.Header>
            <ClaimSummary.CardGroupLabel>
              <ClaimSummary.Icon>
                <IconUserShield />
              </ClaimSummary.Icon>
              Damnificado
            </ClaimSummary.CardGroupLabel>

            {withEdit && <EditClaimButton data={data} segment='victim' />}
          </ClaimSummary.Header>
          <ClaimSummary.CardGroupSeparator />
          <ClaimSummary.CardGroupFields className={cn('grid grid-cols-1')}>
            <ClaimSummary.Victim segment='victim' data={data.victim!} />
          </ClaimSummary.CardGroupFields>
        </ClaimSummary.CardGroup>

        {(data.claimants || []).length > 0 && (
          <ClaimSummary.Claimants claimants={data.claimants!} />
        )}

        <ClaimSummary.Damages damages={data.damages!} />

        <ClaimSummary.CardGroup>
          <ClaimSummary.Header>
            <ClaimSummary.CardGroupLabel>
              <ClaimSummary.Icon>
                <IconFileDescription />
              </ClaimSummary.Icon>
              Datos del siniestro
            </ClaimSummary.CardGroupLabel>
            {withEdit && <EditClaimButton data={data} segment='occurrence' />}
          </ClaimSummary.Header>

          <ClaimSummary.CardGroupSeparator />

          <ClaimSummary.CardGroupFields>
            {data.occurrence.sinister_date && (
              <ClaimSummary.CardAttribute>
                <ClaimSummary.CardLabel>Fecha</ClaimSummary.CardLabel>
                <ClaimSummary.CardValue>
                  {format(
                    new Date(data.occurrence.sinister_date!),
                    'dd/MM/yyyy HH:mm',
                  )}
                </ClaimSummary.CardValue>
              </ClaimSummary.CardAttribute>
            )}

            {data.policyholder_license_plate && (
              <ClaimSummary.CardAttribute>
                <ClaimSummary.CardLabel>
                  Patente asegurado
                </ClaimSummary.CardLabel>
                <ClaimSummary.CardValue>
                  {data.policyholder_license_plate}
                </ClaimSummary.CardValue>
              </ClaimSummary.CardAttribute>
            )}

            {data.policyholder_policy_number && (
              <ClaimSummary.CardAttribute>
                <ClaimSummary.CardLabel>
                  Póliza asegurado
                </ClaimSummary.CardLabel>
                <ClaimSummary.CardValue>
                  {data.policyholder_policy_number}
                </ClaimSummary.CardValue>
              </ClaimSummary.CardAttribute>
            )}

            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>País</ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {getCountryLabel(data.occurrence.country)}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>Provincia</ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {getStateLabel(data.occurrence.country, data.occurrence!.state)}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>Ciudad</ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {data.occurrence!.city}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>Dirección</ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {data.occurrence!.address}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>Código Postal</ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {data.occurrence!.zip_code}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>Monto reclamado</ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {currencyFormat(data.claimed_amount)}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>
                Forma de ocurrencia
              </ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {data.occurrence.description}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
          </ClaimSummary.CardGroupFields>

          <ClaimSummary.CardGroupSeparator />

          <ClaimSummary.CardGroupFields className={cn('grid grid-cols-1')}>
            <ClaimSummary.Occurrence
              data={data.occurrence}
              segment='occurrence'
            />
          </ClaimSummary.CardGroupFields>
        </ClaimSummary.CardGroup>

        <ClaimSummary.CardGroup>
          <ClaimSummary.Header>
            <ClaimSummary.CardGroupLabel>
              <ClaimSummary.Icon>
                <IconMapPin />
              </ClaimSummary.Icon>
              Ubicación
            </ClaimSummary.CardGroupLabel>
          </ClaimSummary.Header>
          <ClaimSummary.CardGroupSeparator />

          <ClaimSummary.CardGroupFields>
            <ClaimSummary.CardAttribute>
              <ClaimSummary.CardLabel>Provincia</ClaimSummary.CardLabel>
              <ClaimSummary.CardValue>
                {getStateLabel(
                  DEFAULT_COUNTRY_CODE,
                  data.desired_support_location_state,
                )}
              </ClaimSummary.CardValue>
            </ClaimSummary.CardAttribute>
            {data.desired_support_location_state_municipality && (
              <ClaimSummary.CardAttribute>
                <ClaimSummary.CardLabel>Partido</ClaimSummary.CardLabel>
                <ClaimSummary.CardValue>
                  {data.desired_support_location_state_municipality}
                </ClaimSummary.CardValue>
              </ClaimSummary.CardAttribute>
            )}
          </ClaimSummary.CardGroupFields>
        </ClaimSummary.CardGroup>
      </ClaimSummary.CardList>
    </ClaimUpdateProvider>
  );
};
