'use client';

import { Button, cn } from 'ui';
import { Router } from 'next/router';
import { useEffect } from 'react';
import { useGoogle } from '../hooks/useGoogle';
import { GoogleIcon } from './GoogleIcon';

export const GoogleBtn = ({
  variant = 'color',
  ...props
}: {
  isLoading?: boolean;
  onAccept: (access_token: string) => void;
  small?: boolean;
  variant?: 'color' | 'white';
}) => {
  const { initGoogleClient, googleClient } = useGoogle(props.onAccept);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = (e) => initGoogleClient();

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [Router]);

  console.log('props.loading', props.isLoading);

  return (
    <Button
      loading={props.isLoading}
      variant={'outline'}
      className={cn(
        variant === 'white'
          ? 'bg-primary text-white hover:bg-primary hover:text-white h-11.1 border-0 rounded-[.625rem]'
          : 'bg-gray-100 hover:bg-gray-200',
        'w-full',
      )}
      onClick={() => (!!googleClient ? googleClient.requestAccessToken() : {})}
    >
      <GoogleIcon
        style={{
          width: !props.small ? '1.6rem' : '1rem',
        }}
        variant={variant}
      />
      <span className='ml-2 font-semibold text-[1rem]'>
        Continuar con Google
      </span>
    </Button>
  );
};
