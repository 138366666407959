'use client';

import { cn } from 'ui';
import { cva, VariantProps } from 'class-variance-authority';

const variants = cva('', {
  variants: {
    size: {
      sm: 'w-4 h-4',
      md: 'w-6 h-6',
      lg: 'w-8 h-8',
      xl: 'w-10 h-10',
      '2xl': 'w-12 h-12',
    },
  },
  defaultVariants: {
    size: 'sm',
  },
});

interface PiramidLogoProps extends VariantProps<typeof variants> {
  className?: string;
  variant?: 'black' | 'white' | 'orange';
}

export const PiramidLogo = ({
  variant = 'black',
  className,
  size,
}: PiramidLogoProps) => {
  return (
    <span
      className={cn(
        variants({
          size,
          className,
        }),
        '[&_path]:fill-primary',
      )}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        id='Capa_1'
        viewBox='0 0 688.28 688.28'
      >
        <defs>
          <style>{'.cls-1{fill:#1a1b1e}'}</style>
        </defs>
        <path
          d='m362.18 96.49 251.48 385.27-251.48 129.45V96.49z'
          className='cls-1'
        />
        <path
          d='m380.27 157.33 207.27 317.53-207.27 106.68V157.33M344.08 35.66v605.22l52.76-27.16 207.27-106.68 35.68-18.36-21.93-33.6-207.27-317.53-66.51-101.88Z'
          className='cls-1'
        />
        <path
          d='M308.01 157.24v424.21L100.74 474.77l207.27-317.53M344.2 35.58l-66.51 101.88L70.43 454.99l-21.93 33.6 35.68 18.36 207.27 106.68 52.76 27.16V35.58Z'
          className='cls-1'
        />
      </svg>
    </span>
  );
};
