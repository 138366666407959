import { DocumentEmptyStateIcon } from 'shared-components';
import { UploadFileCustom } from './UploadFileCustom';
import { UserType } from 'database';
import { TokenizeFileRequestBody, TokenizeFileResponseBody } from 'bff';
import { EmptyStateIcon, NextEmptyStateIcon } from 'ui';

type DocumentsEmptyStateProps = {
  initialPendingFileRequests: any;
  tokenizeFile?: (
    input: TokenizeFileRequestBody,
  ) => Promise<TokenizeFileResponseBody>;
  addUntaggedFiles: (files: any[]) => Promise<any>;
  userType?: UserType;
  withPlaceholder: boolean;
};

const getEmptyStateMessage = (userType?: UserType): string => {
  if (!userType || userType === 'inhouse_lawyer' || userType === 'third') {
    return '';
  }

  return 'Solicita archivos al reclamante';
};

export const DocumentsEmptyState = ({
  initialPendingFileRequests,
  tokenizeFile,
  addUntaggedFiles,
  userType,
  withPlaceholder,
}: DocumentsEmptyStateProps) => {
  const uploadFileProps = {
    tokenizeFile: (file: any) => tokenizeFile?.(file),
    classNames: {
      input: 'h-[12.8rem] border-slate-300',
    },
    addFiles: addUntaggedFiles,
    uploadMessage:
      userType === 'inhouse_lawyer'
        ? 'Agrega archivos adicionales'
        : 'No se encontraron archivos',
  };

  if (withPlaceholder && tokenizeFile) {
    return (
      <div>
        {/* @ts-ignore */}
        <UploadFileCustom {...uploadFileProps} />
      </div>
    );
  }

  const emptyStateMessage = getEmptyStateMessage(userType);

  return (
    <div className='flex flex-col items-center p-10 border border-dashed border-stone-300 rounded-xl'>
      <NextEmptyStateIcon />
      <p className='text-foreground font-semibold text-xs text-center'>
        No se encontraron archivos <br />
        <span className='text-stone-400 font-medium text-2xs mt-2'>
          {emptyStateMessage}
        </span>
      </p>
    </div>
  );
};
